import { bool, func, object } from "prop-types";
import React from "react";
import Filters from "../Filters/Filters";
import FilterGroup from "./FilterGroup";
import Loading from "../../UI/Loading/Loading";
import useConditionalFilters from "../../hooks/useConditionalFilters";
import Flex from "../../UI/Flex/Flex";

function MenuFilter({
  filterType,
  select,
  open,
  menuFilterShortList,
  dataSourceAccessConfig,
  additionalTheme,
  comparisonModeItem,
  selectPowerEditorModeFilter,
  searchFilterLoading,
  loadValues,
  selectedValues,
}) {
  useConditionalFilters(filterType);

  if (filterType.loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <FilterGroup
      name={filterType.displayName}
      open={open}
      cy="left-menu-filter-group"
      filter={filterType}
      select={select}
      additionalTheme={additionalTheme}
      loadValues={loadValues}
      selectedValues={selectedValues}
    >
      {filterType.values ? (
        <Filters
          filterType={filterType}
          select={select}
          menuFilterShortList={menuFilterShortList}
          dataSourceAccessConfig={dataSourceAccessConfig}
          comparisonModeItem={comparisonModeItem}
          selectPowerEditorModeFilter={selectPowerEditorModeFilter}
          additionalTheme={additionalTheme}
          searchFilterLoading={searchFilterLoading}
        />
      ) : (
        <Flex mb2>
          <Loading />
        </Flex>
      )}
    </FilterGroup>
  );
}

MenuFilter.propTypes = {
  filterType: object.isRequired,
  select: func.isRequired,
  open: bool,
};

export default React.memo(MenuFilter);
