import axios from "axios";
import { getCookie, originalQueryParsed } from "./utils/browser";
import { store } from "./store";
import { showActionToast } from "./store/actions/message";
import { scheduleMessage } from "./utils/constants/constants";
import { getToken } from "./utils/getToken";
import { isRunningInTest, apiEndpoint } from "./utils/env";
import { getTenantDomainFromURL } from "./utils/tenant";

// The FE subdomain must be set to "cypress_easy_tests" or just "domain" for this to work.
const isCypressEasyTestsFrontend =
  getTenantDomainFromURL(window.location.href) === "cypress_easy_tests";

const base =
  process.env.NODE_ENV === "test"
    ? "http://test.com/"
    : window.Cypress || isCypressEasyTestsFrontend
    ? "https://faked-domain-for-cypress-to-prevent-stray-requests.com"
    : apiEndpoint;

export const apiUrlObject = new URL(base);

const shouldUseLocalResponseCaching =
  process.env.REACT_APP_LOCAL_API_CACHE &&
  !isRunningInTest &&
  !isCypressEasyTestsFrontend;

const axiosAdapter = shouldUseLocalResponseCaching
  ? // Code-split lazy-load the cache adapter to not include its code into the production bundle.
    async (config) =>
      (await import("./utils/axiosLocalCacheAdapter")).default.adapter(config)
  : // Just use the default axios adapter.
    undefined;

const instance = axios.create({
  baseURL: base,
  adapter: axiosAdapter,
});

instance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  if (!config.headers.Authorization && getToken()) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  if (originalQueryParsed.activeGroupAlias) {
    config.headers["X-Active-Group-Alias"] =
      originalQueryParsed.activeGroupAlias;
  }
  // Forward xdebug/clockwork cookies to the API.
  const xdebugSessionValue = getCookie("XDEBUG_SESSION");
  const clockworkProfileValue = getCookie("clockwork-profile");
  if (!config.params) {
    config.params = {};
  }
  if (xdebugSessionValue) {
    config.params.XDEBUG_SESSION_START = xdebugSessionValue;
  }
  if (clockworkProfileValue != null) {
    config.params["clockwork-profile"] = clockworkProfileValue;
  }

  config.startTime = new Date();
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 202) {
      const settings = response.config.data && JSON.parse(response.config.data);
      store.dispatch(
        showActionToast(scheduleMessage, response.config.url, settings)
      );
    }

    response.config.duration = new Date() - response.config?.startTime;
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    error.duration = new Date() - error.config?.startTime;

    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }

    // 401 errors.
    localStorage.clear();
    window.location = "/";
    return Promise.reject(error);
  }
);

export default instance;

/**
 * Retries an Axios request when a timeout error occurs.
 *
 * @param {Promise} axiosRequest - The Axios request to be made.
 * @param {Function} [onRetry] - An optional callback function to execute on each retry.
 * @returns {Promise} The resolved value of the Axios request if successful.
 * @throws {Error} Rethrows any error that is not a timeout error.
 */
export async function retryOnTimeout(axiosRequest, onRetry) {
  // Infinite loop to keep retrying the request until it succeeds or a non-timeout error occurs.
  while (1) {
    try {
      // Attempt to await the Axios request.
      return await axiosRequest;
    } catch (e) {
      // Check if the caught error is a timeout error.
      if (isTimeoutError(e)) {
        // If it is, call the onRetry callback if provided.
        if (onRetry) {
          onRetry();
        }
      } else {
        // If it's not a timeout error, rethrow the error for handling elsewhere.
        throw e;
      }
    }
  }

  /**
   * Checks if the error is a timeout error.
   *
   * @param {Error} e - The error object to check.
   * @returns {boolean} True if the error is a timeout error, otherwise false.
   */
  function isTimeoutError(e) {
    return e?.response?.status === 504; // HTTP 504 status indicates a gateway timeout.
  }
}
