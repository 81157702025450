import React, { useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { getPinnedRows } from "../functions/tableMapper";

const rowHeight = 29; // 29px;

const TrContainer = styled.tr(
  ({
    theme,
    index,
    totalRow,
    forceBold,
    hasRowExpand,
    background,
    bordered,
    sticky,
    stickyPinned,
    powerRow,
    hoverRowEffectBorder,
    totalsFromBEOnTop,
    expandedRowBackground,
    pinnedRow,
    hideRowZebraBackground,
  }) => `
    cursor: ${hasRowExpand ? "pointer" : "auto"};
    border-top: ${bordered ? `1px solid ${theme.gray.gray900}` : "inherit"};
    border-bottom: ${bordered ? `1px solid ${theme.gray.gray900}` : "inherit"};
    ${
      !hideRowZebraBackground &&
      `
       background: ${
         index !== -1 &&
         (index % 2 === 0
           ? theme.gray900
           : `rgba(0,0,0,${theme.type === "dark" ? 0.15 : 0.05})`)
       };
      `
    };
    background: ${expandedRowBackground || (background && theme.primary)};
    color: ${background && theme.primary && "white"};
    vertical-align: middle;
    ${
      (totalRow || forceBold) &&
      `
        font-weight: bold;
        td {
            background-color: ${
              typeof forceBold === "string" ? forceBold : "black"
            } !important;
            color: white;
        }
        `
    };

    ${
      !!(sticky && totalRow) &&
      `
      position: sticky;
      z-index: 20;
      bottom: 0;
      left: 0;
    `
    };

    

    ${
      !!(sticky && stickyPinned) &&
      `
      position: sticky;
      z-index: 20;
      ${[pinnedRow.position]}: ${pinnedRow.offset}px;
      left: 0;
      td {
        background: ${
          theme.type === "dark" ? theme.gray.gray900 : theme.gray.gray400
        } !important;
        color: white;
      }
    `
    };

    ${
      !!(sticky && totalsFromBEOnTop) &&
      `
      position: sticky;
      z-index: 20;
      top: 29px;
    `
    };

    ${
      powerRow &&
      `
      td {
        background: ${
          theme.type === "light" ? "rgb(255, 255, 255)" : "rgb(0,0,0)"
        };
        ${
          !hoverRowEffectBorder &&
          `
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        `
        };
        font-weight: 700;
      }
    `
    };

    &:hover {
      ${
        hoverRowEffectBorder
          ? `
          td {
            border-top: 1px solid ${theme.notification.infoMain};
            border-bottom: 1px solid ${theme.notification.infoMain};
          }
      `
          : `
      td {
        background: ${
          theme.type === "dark" ? "black" : theme.gray.gray400
        } !important;
      }
      `
      };
      
      & td {
        color: white;
        a {
          color: white;
        }
      }
    };
`
);

export default React.memo(function Tr(props) {
  const {
    pinnedRows,
    sticky,
    row = {},
    children,
    totals,
    hasParentHeaders,
    tHeadHeight,
  } = props;

  const getPinnedRowItem = useCallback(
    (pin) => row.values.find((val) => val[pin.column] === pin.value),
    [row.values]
  );

  const getPinnedRowIndex = useCallback(
    (pinnedRow) => {
      // for bottom aligned we need check for total row
      // if we have totals then we need to pin to one position top from total
      if (pinnedRow?.position === "bottom") {
        return (
          pinnedRows.slice().reverse().findIndex(getPinnedRowItem) +
          (totals ? 1 : 0)
        );
      }

      // for top pin position we need to check for parent headers
      // if parent headers exists then we need to put pin 2 position after parent
      return (
        pinnedRows.findIndex(getPinnedRowItem) + (hasParentHeaders ? 2 : 1)
      );
    },
    [getPinnedRowItem, pinnedRows, totals, hasParentHeaders]
  );

  const applyPinnedRowOffset = useCallback(() => {
    const pinnedRow = pinnedRows.find(getPinnedRowItem);
    const index = getPinnedRowIndex(pinnedRow);

    // separate offset calculation for position = top
    // table headers can have dynamic height value
    const topOffset =
      (index === 1 ? tHeadHeight : (index - 1) * rowHeight + tHeadHeight) - 1;

    // pinned rows using position sticky so we need set offset from top/bottom
    return pinnedRow
      ? {
          ...pinnedRow,
          offset: pinnedRow.position === "top" ? topOffset : index * rowHeight,
        }
      : null;
  }, [getPinnedRowIndex, getPinnedRowItem, pinnedRows, tHeadHeight]);

  const pinned = useMemo(() => {
    if (!pinnedRows) return {};

    const stickyPinned = !!(
      sticky && row.values.find((val) => getPinnedRows(val, pinnedRows))
    );

    const pinnedRow = applyPinnedRowOffset();

    return {
      stickyPinned,
      pinnedRow,
    };
  }, [applyPinnedRowOffset, pinnedRows, row, sticky]);

  return (
    <TrContainer {...props} {...pinned}>
      {children}
    </TrContainer>
  );
});
