import React, { useMemo, useCallback } from "react";
import MenuFilter from "./MenuFilter";
import {
  FILTER_TYPE_CHECKBOX,
  emptyArray,
  FILTER_TYPE_DATE_PRESETS,
} from "../../utils/constants/constants";
import { useSelector } from "react-redux";

export default function SingleDataSourceMenuFiltersItem({
  item,
  queryUuid,
  additionalTheme,
  dataSourceAccessConfig,
  select,
  loadSingleFieldByName,
}) {
  const selectedValues = useSelector(
    (state) =>
      state.layout.singleQueryFiltersByQueryUuid[queryUuid]?.fieldsByName[
        item.fieldName
      ]?.selectedValues ?? emptyArray
  );
  const isDateLike = ["date", "datetime"].includes(item.fieldType);

  const loadValues = useMemo(() => {
    return !isDateLike ? () => loadSingleFieldByName(item.fieldName) : null;
  }, [isDateLike, item.fieldName, loadSingleFieldByName]);

  const filterType = useMemo(() => {
    const values =
      item.values?.map((value) => ({
        key: item.fieldName,
        type: item.fieldName,
        value,
        label: value,
        checked: selectedValues.includes(value),
      })) ?? (isDateLike ? [{ key: item.fieldName }] : null);

    return {
      type: isDateLike ? FILTER_TYPE_DATE_PRESETS : FILTER_TYPE_CHECKBOX,
      name: item.fieldName,
      displayName: item.fieldDisplayName,
      values,
    };
  }, [
    isDateLike,
    item.fieldDisplayName,
    item.fieldName,
    item.values,
    selectedValues,
  ]);

  // We wrap the select function to ensure that the
  // singleQueryFiltersUuid option is forwarded.
  const selectSingleQuery = useCallback(
    (filter, type, shouldReloadCharts, options = {}) => {
      return select(filter, type, shouldReloadCharts, {
        ...options,
        singleQueryFiltersUuid: queryUuid,
      });
    },
    [queryUuid, select]
  );

  return (
    <MenuFilter
      filterType={filterType}
      additionalTheme={additionalTheme}
      dataSourceAccessConfig={dataSourceAccessConfig}
      select={selectSingleQuery}
      loadValues={loadValues}
      selectedValues={selectedValues}
    />
  );
}
