import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { showToastWithTimeout } from "../../store/actions/message";
import { normalizeError, handleError } from "../../utils/errorHandling";
import axios from "../../axios";
import { chartTypeKeyMap } from "../../utils/constants/chartConstants";
import {
  getSelectorForCurrentPageByUuid,
  getFirstVisualizationForPage,
  getSingleDataSourceFiltersQueryUuidByPage,
} from "../../utils/pages";
import useMemoDeepCompare from "../../utils/useMemoDeep";
import { assembleEmptySingleQueryFiltersFromActiveTableStore } from "../../utils/menuFilters";
import { useShallowEqualSelector } from "../../store";
import produce from "immer";

export default function (pageUuid) {
  const dispatch = useDispatch();
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  const isEnabledForThisPage = page?.singleDataSourceMenuFilters;

  const visualization = getFirstVisualizationForPage(page);
  const queryUuid = getSingleDataSourceFiltersQueryUuidByPage(page);
  const isActiveTable =
    visualization?.settings.type === chartTypeKeyMap.ActiveTable;
  const { currentTable: currentActiveTable, queryFields } =
    useShallowEqualSelector((state) => state.activeTable);
  const currentViewUuid = useSelector(
    (state) => state.activeTable.currentViewUuid
  );
  const currentActiveTableUuid = currentActiveTable?.uuid;
  const firstVisualizationActiveTableUuid =
    visualization?.settings.activeTableUuid;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const abortControllersRef = useRef(new Set());

  const loadSingleFieldByName = useCallback(
    async (fieldName) => {
      const abortController = new AbortController();
      try {
        abortControllersRef.current.add(abortController);

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.loading = true;
            }
          })
        );

        const promise = axios.post(
          `/api/v1/queries/${queryUuid}/get-multiple-filters`,
          {
            per_page: 100_000,
            fields: [
              {
                name: fieldName,
                fetch: true,
              },
            ],
          },
          { signal: abortController.signal }
        );

        const result = (await promise).data.data;

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.values = result[0].values;
              row.loading = false;
            }
          })
        );
      } catch (e) {
        handleError(dispatch, e);
      } finally {
        abortControllersRef.current.delete(abortController);
      }
    },
    [dispatch, queryUuid]
  );

  const fields = useMemoDeepCompare(
    useMemo(() => {
      return (
        currentActiveTable &&
        getCurrentFields(currentActiveTable, currentViewUuid)
      );
    }, [currentActiveTable, currentViewUuid])
  );

  const isEnabledAndActiveTableReady = useMemo(() => {
    if (!isEnabledForThisPage) {
      return false;
    }

    return !(
      isActiveTable &&
      (!currentActiveTableUuid ||
        currentActiveTableUuid !== firstVisualizationActiveTableUuid)
    );
  }, [
    currentActiveTableUuid,
    firstVisualizationActiveTableUuid,
    isActiveTable,
    isEnabledForThisPage,
  ]);

  useEffect(() => {
    if (isEnabledAndActiveTableReady && !queryUuid) {
      const errorMessage = "Query was not found.";
      showToastWithTimeout(dispatch, errorMessage, "danger");
      setError(
        normalizeError({
          message: errorMessage,
        })
      );
    }
  }, [dispatch, isEnabledAndActiveTableReady, queryUuid]);

  const emptySingleQueryFilters = useMemo(() => {
    if (!queryFields || !fields) {
      return;
    }

    return assembleEmptySingleQueryFiltersFromActiveTableStore(
      queryFields,
      fields
    );
  }, [fields, queryFields]);

  useEffect(() => {
    if (!emptySingleQueryFilters) {
      return;
    }

    setData(emptySingleQueryFilters);

    return () => {
      for (const controller of abortControllersRef.current) {
        controller.abort();
      }
      setLoading(false);
      setData(null);
      setError(null);
    };
  }, [emptySingleQueryFilters]);

  return {
    isEnabledForThisPage,
    loading,
    data,
    error,
    queryUuid,
    loadSingleFieldByName,
  };
}

function getCurrentFields(currentActiveTable, currentViewUuid) {
  const views = currentActiveTable.views ?? [];

  const view = views.find((v) => v.uuid === currentViewUuid) ?? views[0];
  return (
    view?.visibleFields.map((v) => v.field) ??
    currentActiveTable.columns.map((c) => c.name)
  );
}
