import React, { useState } from "react";
import FilterContainer from "./FilterContainer";
import ExploreListInput from "../DataExplorerUI/ExplorerInputs/ExploreListInput";
import produce from "immer";
import ValueOrientationDiv from "./ValueOrientationDiv";
import ExplorerNumberInput from "./ExplorerNumberInput";
import ExplorerDateInput from "./DateInput/ExplorerDateInput";
import FilterLoadingOrError from "./FilterLoadingOrError";

export default function FilterEditorValue(props) {
  const {
    setValues,
    filter,
    dataType = "string",
    loading,
    error,
    setError,
    setLoading,
    ...rest
  } = props;

  const values = valuesOrBlank();

  const [addingValue, setAddingValue] = useState(false);
  const [showValues, setShowValuesAtIndex] = useState(null);
  if (!filter?.operator && dataType === "string") return null;

  const lastValueIndex = filter?.values?.length - 1;
  const fixedInputCount = filter.operator === "range";

  const handleSetValue = (valueIndex, val) => {
    const newValues = produce(filter.values || [], (draft) => {
      draft[valueIndex] = val;
    });
    setStatesToChanged();

    function setStatesToChanged() {
      setValues(props.filterIndex, newValues);
      setShowValuesAtIndex(null);
      setAddingValue(false);
    }
  };

  function setNumberValue(val) {
    setValues(props.filterIndex, [val]);
  }

  function setDateValues(values) {
    setValues(props.filterIndex, values);
  }

  const showHandleAddValue = (i) => {
    const allowsMultipleValues = ["==", "!="].find(
      (f) => filter?.operator === f
    );
    if (!allowsMultipleValues || addingValue) return false;
    return i === lastValueIndex && filter?.values[lastValueIndex] !== undefined;
  };

  // Only show error message when requiring list
  const hasEnumList = filter?.operator === "==" || filter?.operator === "!=";

  const fieldSelectList = (valueIndex) => (
    <FilterContainer key={valueIndex} column={0} width={208}>
      {hasEnumList && error ? (
        <FilterLoadingOrError
          error={error}
          setError={setError}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <ExploreListInput
          filter={filter}
          valueIndex={valueIndex}
          handleSetValue={handleSetValue}
          setShowValues={setShowValuesAtIndex}
          showHandleAddValue={showHandleAddValue(valueIndex)}
          showValues={showValues}
          handleAddValue={setAddingValue}
          fixedInputCount={fixedInputCount}
          loading={loading}
          {...rest}
        />
      )}
    </FilterContainer>
  );

  const emptyValues = !filter.values || filter.values.length <= 0;
  if (emptyValues && dataType === "string") {
    return fieldSelectList(0);
  }

  function valuesOrBlank() {
    return !filter?.values?.length ? [""] : filter.values;
  }

  // extract toggle of list to underlying component for string
  return dataType === "string" ? (
    <ValueOrientationDiv operator={filter.operator}>
      {dataType === "string"
        ? values.map((value, valueIndex) => fieldSelectList(valueIndex))
        : null}
      {addingValue && dataType === "string"
        ? fieldSelectList(filter.values.length)
        : null}
    </ValueOrientationDiv>
  ) : dataType === "number" ? (
    <ValueOrientationDiv>
      <ExplorerNumberInput
        sampleResults={props.uniques}
        operator={filter.operator}
        handleAddValue={setNumberValue}
        value={(filter.values ?? [])[0]}
      />
    </ValueOrientationDiv>
  ) : (
    <ValueOrientationDiv>
      <ExplorerDateInput
        sampleResults={props.uniques}
        operator={filter.operator}
        handleAddValue={setDateValues}
        values={values}
      />
    </ValueOrientationDiv>
  );
}
