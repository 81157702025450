import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import SelectedFilters from "../Filters/SelectedFilters";

const MenuDropdown = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.375rem 0.75rem 1.125rem;
    color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.secondary};
    border-bottom: 1px solid ${
      theme.menuPrimary ? "rgba(255,255, 255, 0.1)" : theme.divider
    };
`
);

const MenuDropdownTitle = styled.div(
  () => `
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
    text-align: left;
    user-select: none;

    & p {
        margin: 0;
    }
`
);

const Icon = styled.div`
  margin-right: 8px;
  font-size: 12px;
  width: 18px;
  text-align: center;
`;

export default function FilterGroup(props) {
  const {
    name,
    open,
    children,
    cy,
    filter,
    select,
    additionalTheme,
    loadValues,
    selectedValues,
  } = props;
  const [showFilters, setShowFilters] = useState(!!open);
  const loading = filter?.loading;
  const values = filter?.values;
  useEffect(() => {
    const pendingLazyLoad = loadValues && !values && !loading;
    if (pendingLazyLoad && showFilters) {
      loadValues();
    }
  }, [loadValues, loading, showFilters, values]);

  return (
    <MenuDropdown data-cy="menu-dropdown">
      <MenuDropdownTitle
        onClick={() => setShowFilters(!showFilters)}
        data-cy={cy}
      >
        <p>{name}</p>
        <Icon>
          <FontAwesomeIcon
            icon={["fas", showFilters ? "caret-up" : "caret-down"]}
          />
        </Icon>
      </MenuDropdownTitle>
      {showFilters ? children : null}

      <SelectedFilters
        filter={filter}
        select={select}
        showFilters={showFilters}
        additionalTheme={additionalTheme}
        selectedValues={selectedValues}
      />
    </MenuDropdown>
  );
}

FilterGroup.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
};
