import { useEffect, useState } from "react";
import produce from "immer";
import isEqual from "react-fast-compare";
import { useFilters } from "../../../Pages/DataManagement/DataExplorer/hooks/useExplorerFilters/useFilters";
import { isEqualWith, omit } from "lodash-es";

const defaultInitialState = { visibleFields: [], filters: [] };
export default function useActiveTableViewManager({
  activeViewInitialState = defaultInitialState,
  allColumns,
  handleSaveView,
  user,
}) {
  const [previousViewInitialState, setPreviousViewInitialState] =
    useState(null);
  const [nextView, setNextView] = useState(activeViewInitialState);
  const [nextColumns, setNextColumns] = useState(allColumns); // all columns with views settings
  const [name, setName] = useState(activeViewInitialState?.name || "");
  // filters only used on create view screen for copy template from another view
  const [filters, setFilters] = useState(null);

  const [processing, setProcessing] = useState(false);
  const filterApi = useFilters(activeViewInitialState.filters ?? []);

  useEffect(() => {
    if (
      !previousViewInitialState ||
      !isEqual(previousViewInitialState, activeViewInitialState)
    ) {
      setPreviousViewInitialState(activeViewInitialState);
      const nextMergedColumns = convertedColumns(
        activeViewInitialState,
        allColumns
      );
      setNextColumns(nextMergedColumns);
      setNextView(activeViewInitialState);
      setName(activeViewInitialState?.name || "");
      filterApi.setDraftFilters(activeViewInitialState?.filters || []);
      setProcessing(false);
    }
  }, [activeViewInitialState, allColumns, previousViewInitialState, filterApi]);

  function setVisible(columnUuid) {
    const columnIndex = nextColumns.findIndex((c) => c.colId === columnUuid);
    const updatedColumns = produce(nextColumns, (draft) => {
      draft[columnIndex].visible = true;
    });
    return setNextColumns(updatedColumns);
  }

  function setInvisible(columnUuid) {
    const columnIndex = nextColumns.findIndex((c) => c.colId === columnUuid);
    const updatedColumns = produce(nextColumns, (draft) => {
      draft[columnIndex].visible = false;
    });
    return setNextColumns(updatedColumns);
  }

  function setAllVisible() {
    const updatedColumns = nextColumns.map((c) => ({ ...c, visible: true }));
    return setNextColumns(updatedColumns);
  }

  function setAllInvisible() {
    const updatedColumns = nextColumns.map((c) => ({ ...c, visible: false }));
    return setNextColumns(updatedColumns);
  }

  const visibleColumns = nextColumns.filter((c) => c.visible);

  const nextViewSettings = {
    ...nextView,
    visibleFields: visibleColumns,
    name,
    filters: filters ?? filterApi.draftFilters,
  };

  const disabled = processing || !name;

  const convertedActiveViewInitialState = {
    ...activeViewInitialState,
    visibleFields: convertedColumns(activeViewInitialState, allColumns).filter(
      (c) => c.visible
    ),
    filters: activeViewInitialState?.filters ?? [],
  };

  // visibleFields has cellEditorParams for image types
  // it include handleChange function which changes to new instanse on update
  // we should skeep this comparison
  function comparator(a, b) {
    if (a instanceof Function && b instanceof Function) {
      return true;
    }
  }

  const isDirty = !isEqualWith(
    // updatedAt will change afte api call so we do not need to compare it
    omit(convertedActiveViewInitialState, "updatedAt"),
    omit(nextViewSettings, "updatedAt"),
    comparator
  );

  function saveView() {
    setProcessing(true);
    handleSaveView(nextViewSettings);
  }

  function setDefaultViewFlag() {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        isDefaultView: !displaySettings.isDefaultView,
      },
    });
  }

  function setViewSplitBy(columnName) {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        splitBy: columnName,
      },
    });
  }

  function setVisibilityViewUser(isPrivate) {
    const displaySettings = nextView.displaySettings ?? {};

    return setNextView({
      ...nextView,
      displaySettings: {
        ...displaySettings,
        viewVisibilityUserUuid: isPrivate ? user.uuid : null,
      },
    });
  }

  function setDisplaySettings(settings) {
    setNextView({ ...nextView, displaySettings: settings });
  }

  return {
    nextColumns,
    setNextColumns,
    visibleColumns,
    setVisible,
    setInvisible,
    setAllVisible,
    setAllInvisible,
    nextViewSettings,
    name,
    setName,
    disabled,
    saveView,
    processing,
    isDirty,
    filterApi,
    displaySettings: nextView.displaySettings ?? {},
    setDefaultViewFlag,
    setVisibilityViewUser,
    setNextView,
    setFilters,
    setDisplaySettings,
    setViewSplitBy,
  };
}

function convertedColumns(viewState, allColumns) {
  // Map visible fields first
  const visibleColumns = viewState.visibleFields
    .map((visibleField) =>
      // for some reason this can be undefined, we need to filter out undefined values
      allColumns.find((column) => column.colId === visibleField.colId)
    )
    .filter(Boolean)
    .map((c) => ({ ...c, visible: true }));

  const remainingColumns = allColumns
    .filter((c) => !viewState.visibleFields.find((f) => f.colId === c.colId))
    .map((c) => ({ ...c, visible: false }));

  const orderedColumns = [...visibleColumns, ...remainingColumns];

  return orderedColumns;
}
