import React from "react";
import Input from "../../../UI/Form/Input/Input";
import styled from "@emotion/styled";

const DynamicLimitContainer = styled.div`
  align-items: center;
  color: ${(props) => props.theme.text.secondary};
  margin-right: 20px;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export default React.memo(function LimitRowsInput({
  limit,
  rowsQuantity,
  displayRows,
  sticky,
  setLimit,
}) {
  if (!displayRows || !rowsQuantity || sticky) {
    return null;
  }

  return (
    <DynamicLimitContainer>
      <Label>Display Rows:&nbsp;</Label>
      <Input value={limit} onChange={setLimit} type="number" small />
      <Label>&nbsp;Total:&nbsp;{rowsQuantity}</Label>
    </DynamicLimitContainer>
  );
});
