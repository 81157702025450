import React from "react";
import Loading from "../../UI/Loading/Loading";
import { useTheme } from "emotion-theming";
import SingleDataSourceMenuFiltersItem from "./SingleDataSourceMenuFiltersItem";
import Flex from "../../UI/Flex/Flex";
import { systemColumns } from "../../charts/ActiveTable/useActiveTableSettings";

export default function SingleDataSourceMenuFilters({
  additionalTheme,
  dataSourceAccessConfig,
  select,
  singleDataSourceFilters,
}) {
  const theme = useTheme();
  const { loading, data, error, queryUuid, loadSingleFieldByName } =
    singleDataSourceFilters;

  if (loading || !data) {
    return (
      <Flex>
        <Loading />
      </Flex>
    );
  }

  if (error) {
    return (
      <div style={{ color: theme.notification.errorMain }}>{error.message}</div>
    );
  }

  const filteredData = (data ?? []).filter(
    (item) => !systemColumns.includes(item.fieldName)
  );

  return (
    <div>
      {filteredData.map((row) => (
        <SingleDataSourceMenuFiltersItem
          key={row.fieldName}
          dataSourceAccessConfig={dataSourceAccessConfig}
          additionalTheme={additionalTheme}
          select={select}
          item={row}
          queryUuid={queryUuid}
          loadSingleFieldByName={loadSingleFieldByName}
        />
      ))}
    </div>
  );
}
