import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";
import Input from "../../../../UI/Form/Input/Input";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import { FieldWrapper } from "./GlobalSettings";
import ZebraBackgroundSettings from "./ZebraBackgroundSettings";

export const RowStyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-size: 12px !important;
  }
  hr {
    width: 100%;
  }
`;

export default function RowStyles({ setChartState, chart, theme, fields }) {
  const {
    uniqueValueKey,
    freezeLeft,
    freezeWidth,
    emptyFreezeLeft,
    freezeNextColumn,
    rowGroupKey,
    rawRows,
    rawRowsValueKey,
  } = chart;

  function getOption(optionType) {
    switch (optionType) {
      case "unique":
        return fields.find(
          (field) => field.name === uniqueValueKey || field.name === rowGroupKey
        );
      case "rawRows":
        return fields.find((field) => field.name === rawRowsValueKey);
      default:
        return null;
    }
  }

  const uniqueOption = getOption("unique");
  const rawRowsOption = getOption("rawRows");

  const onValueKeyChange = (option, staticKey) => {
    const conditionalKey = uniqueValueKey ? "uniqueValueKey" : "rowGroupKey";
    const key = staticKey ?? conditionalKey;

    setChartState({ ...chart, [key]: option?.name ?? null });
  };

  const rowStyleChange = () => {
    setChartState({
      ...chart,
      rawRows: !!uniqueValueKey,
      uniqueValueKey: uniqueValueKey
        ? null
        : chart.overrides[0]?.name ?? fields[0]?.name,
      ...(rowGroupKey && { rowGroupKey: null }),
    });
  };

  const frozenColumnChange = () => {
    setChartState({
      ...chart,
      freezeWidth: freezeLeft ? null : 0,
      freezeLeft: !freezeLeft,
      emptyFreezeLeft: freezeLeft ? null : emptyFreezeLeft,
    });
  };

  const freezeWidthChange = (e) => {
    setChartState({ ...chart, freezeWidth: e.target.value });
  };

  const freezeCheckboxChange = (name) => {
    setChartState({
      ...chart,
      [name]: !chart[name],
    });
  };

  return (
    <RowStyleContainer>
      <span>Row Style</span>
      <br />
      <Switcher
        leftLabel="auto"
        rightLabel="manually set row key"
        checked={!!uniqueValueKey || !!rowGroupKey}
        handleSwitchChange={rowStyleChange}
      />
      <br />
      {rawRows && (
        <FieldWrapper>
          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={rawRowsOption}
            onChange={(option) => onValueKeyChange(option, "rawRowsValueKey")}
            label="Total label column"
            isClearable
          />
        </FieldWrapper>
      )}
      <br />
      {(uniqueValueKey || rowGroupKey) && (
        <FieldWrapper>
          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={uniqueOption}
            onChange={(option) => onValueKeyChange(option)}
          />
        </FieldWrapper>
      )}
      <br />
      <hr color={theme.background.secondary} />
      <br />
      <span>First column lock</span>
      <br />
      <div style={{ display: "flex" }}>
        <Switcher
          leftLabel="default"
          rightLabel="frozen"
          checked={!!freezeLeft}
          handleSwitchChange={frozenColumnChange}
        />
        {freezeLeft && (
          <Input
            value={freezeWidth}
            type="number"
            style={{ width: "50%" }}
            onChange={freezeWidthChange}
          />
        )}
      </div>
      <br />
      {freezeLeft && (
        <>
          <Checkbox
            checked={!!emptyFreezeLeft}
            label="Add frozen empty column"
            onChange={() => freezeCheckboxChange("emptyFreezeLeft")}
          />
          <br />
          <Checkbox
            checked={!!freezeNextColumn}
            label="Freeze second column"
            onChange={() => freezeCheckboxChange("freezeNextColumn")}
          />
        </>
      )}
      <br />

      <ZebraBackgroundSettings chart={chart} setChartState={setChartState} />
      <br />
      <hr color={theme.background.secondary} />
      <br />
    </RowStyleContainer>
  );
}
